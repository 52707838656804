import { createContext, useContext } from "react"

const defaultState = {
  showButton: true,
  changeShowButton: () => {},
}

export const useCmsContext = () => useContext(CmsContext)

const CmsContext = createContext(defaultState)

export default CmsContext
