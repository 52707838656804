/**
 * locale の画像がなければ fallback image として ja の画像を返す
 */
export const getLocalizedImage = (data, locale) => {
  const img = data.edges.find(({ node }) => node.node_locale === locale)?.node

  const fallbackImg = data.edges.find(
    ({ node }) => node.node_locale === "ja",
  )?.node

  if (fallbackImg === undefined) {
    throw new Error("画像がみつかりません。")
  }

  return img ? img : fallbackImg
}

/**
 * locale に応じた画像のパスを返す
 * locale を省略した場合は en/ja は同じ画像を表示する
 * en は ja 用の画像パスの先頭に /en をつけたもの
 * 現時点では en/ja のみ対応
 */
export const getImage = (path, locale) => {
  const imgDir = "/img"
  if (!/^\/img\//.test(path)) {
    throw new Error("path must start with /img/")
  }
  if (path === undefined) {
    throw new Error("no image path")
  }
  const resPath = path.replace(/^\/img/, "")

  if (locale === undefined || locale === "ja") return path
  if (locale === "en") {
    return `${imgDir}/en${resPath}`
  } else {
    path
  }
}
