import { useState } from "react"

import { getImage } from "funcs/helpers/getImage"

/**
 * locale に en を指定した時に画像が 404 だったら path の ja の画像でフォールバックする
 * @param path 日本語用画像のパス
 * @param locale ja | en 省略時 path の画像を使う
 */
export const LocalizedImage = ({ path, locale = undefined, ...restProps }) => {
  const [imgError, setImgError] = useState(false)

  if (!imgError) {
    return (
      <img
        src={getImage(path, locale)}
        onError={() => {
          setImgError(true)
        }}
        {...restProps}
      />
    )
  } else {
    // locale の画像がなかったら ja の画像を使う
    return <img src={path} {...restProps} />
  }
}
