import { useLayoutEffect, useMemo } from "react"
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { Link as GatsbyLink } from "gatsby"
import { useTranslation } from "react-i18next"

import { useCmsContext } from "context/Cms"
import { Search } from "components/GoogleCustomSearch"
import ClientOnly from "hooks/ClientOnly"
import { LocalizedImage } from "components/LocalizedImage"

const Header = () => {
  const { showButton } = useCmsContext()

  //反映されているが変化しない
  useLayoutEffect(() => {
    //ナビゲーション

    ;(function ($) {
      $(function () {
        var $header = $("#top-head")
        // Nav Fixed
        $(window).scroll(function () {
          if ($(window).scrollTop() > 350) {
            $header.addClass("fixed")
          } else {
            $header.removeClass("fixed")
          }
        })
        // Nav Toggle Button
        $("#nav-toggle").click(function () {
          $header.toggleClass("open")
        })
      })
    })(jQuery)
  }, [])

  useLayoutEffect(() => {
    //検索窓
    document.addEventListener("touchstart", function () {}, true)
  }, [])

  const { locale } = useLocalization()
  const { t } = useTranslation()

  const path = typeof window !== `undefined` ? window.location.pathname : ""

  const toggleMap = useMemo(() => {
    return {
      en: {
        label: "日本語",
        path: path.replace(/^\/en/, ""),
      },
      ja: { label: "ENGLISH", path: `/en${path}` },
    }
  }, [path])

  return (
    <header id="top-head">
      <div className="inner">
        <div id="mobile-head">
          <p className="logo">
            <Link to="/">
              <LocalizedImage
                path="/img/logo_hosii.png"
                alt={t("星井眼科医院", "星井眼科医院")}
              />
            </Link>
          </p>
          <div id="nav-toggle">
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <nav id="global-nav">
          <div className="global_box">
            <div className="top_nav">
              <ul>
                {showButton && (
                  <li className="english_btn">
                    <GatsbyLink to={toggleMap[locale].path}>
                      {toggleMap[locale].label}
                    </GatsbyLink>
                  </li>
                )}
                <li className="info_btn">
                  <a
                    href="https://fujimoto.com/hoshii/contact/"
                  >
                    {t("お問い合わせ")}
                  </a>
                </li>
                <li className="access_btn">
                  <Link to="/access">{t("交通アクセス", "交通アクセス")}</Link>
                </li>
              </ul>
            </div>
            <div className="search">
              <div className="search_window">
                <ClientOnly>
                  <Search resultClass="gcse-searchbox-only" />
                </ClientOnly>
              </div>
            </div>
            <ul className="menu">
              <li>
                <Link to="/">{t("HOME", "HOME")}</Link>
              </li>
              <li>
                <Link to="/appointment_menu">
                  {t("外来受診のご案内", "外来受診のご案内")}
                </Link>
              </li>
              <li>
                <Link to="/hospitalization_guidance">
                  {t("入院のご案内", "入院のご案内")}
                </Link>
              </li>
              <li>
                <a
                  href="http://www.hoshii-contact.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("コンタクトレンズ", "コンタクトレンズ")}
                </a>
              </li>
              <li>
                <Link to="/our_hospital_menu">
                  {t("当院について", "当院について")}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
