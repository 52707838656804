import "modern-normalize/modern-normalize.css"
import { useState } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Header from "components/Header"
import CmsContext from "context/Cms"

const Layout = ({ children, location }) => {
  const { t } = useTranslation()

  const [showButton, setShowButton] = useState(true)
  const changeShowButton = val => setShowButton(val)

  return (
    <>
      <Helmet
        titleTemplate={"%s | " + t("星井眼科医院", "星井眼科医院")}
        defaultTitle={`${t("星井眼科医院", "星井眼科医院")}
      ｜
      ${t(
        "この医院に頼ろう、と思っていただけるような患者さまとの信頼関係をめざしています",
        "眼の健康は私たちにお任せください",
      )}`}
      >
        <meta property="og:site_name" content="星井眼科医院" />
        <meta property="og:title" content="星井眼科医院" />
        <meta
          property="og:description"
          content="星井眼科医院｜眼の健康は私たちにお任せください"
        />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="http://hoshii.fujimoto.com/" />
        <meta
          property="og:image"
          content="http://hoshii.fujimoto.com/img/top/top_bg_1.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <script type="application/javascript">
          {`
            {
              (function(d) {
                var config = {
                  kitId: '${process.env.GATSBY_TYPEKIT_ID}',
                  scriptTimeout: 3000,
                  async: true
                },
                h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
              })(document);
            }
          `}
        </script>
      </Helmet>

      <CmsContext.Provider value={{ showButton, changeShowButton }}>
        <Header key={location.key} />
        {children}
        {/* <Footer /> */}
      </CmsContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
