/**
 * google custom search のスニペットと同じ内容で
 * 検索窓と結果用の div を挿入する
 *
 * @param resultClass "gcse-searchbox-only" and "gcse-searchresults-only"
 */
export const Search = ({ resultClass }) => {
  const cx = "25ad440b5a0494d95" //process.env.GOOGLE_CUSTOM_SEARCH_CX

  let gcse = document.createElement("script")
  gcse.type = "text/javascript"
  gcse.async = true
  gcse.src = "https://cse.google.com/cse.js?cx=" + cx

  let s = document.getElementsByTagName("script")[0]
  s.parentNode.insertBefore(gcse, s)
  return <div className={resultClass}></div>
}
