exports.onRouteUpdate = ({ location, prevLocation }) => {
  // [All pages]
  // fadein
  // scroll page top
  jQuery(function () {
    // フェードイン
    jQuery(".inviewfadeIn").one(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("fadeIn")
        } else {
          jQuery(this).stop().removeClass("fadeIn")
        }
      },
    )

    // 上へスライド
    jQuery(".inviewUp").one(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("Up")
        } else {
          jQuery(this).stop().removeClass("Up")
        }
      },
    )
    // ズームイン
    jQuery(".inviewzoomIn").one(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("zoomIn")
        } else {
          jQuery(this).stop().removeClass("zoomIn")
        }
      },
    )
    // フェードインしながら上へスライド
    jQuery(".inviewfadeInUp").one(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("fadeInUp")
        } else {
          jQuery(this).stop().removeClass("fadeInUp")
        }
      },
    )
    // スライドイン
    jQuery(".inviewslideInUp").one(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("slideInUp")
        } else {
          jQuery(this).stop().removeClass("slideInUp")
        }
      },
    )
    // フェードインしながら上へスライド(繰り返し)
    jQuery(".inviewfadeInUp_r").on(
      "inview",
      function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          jQuery(this).stop().addClass("fadeInUp_r")
        } else {
          jQuery(this).stop().removeClass("fadeInUp_r")
        }
      },
    )
  })

  jQuery(function () {
    var topBtn = jQuery("#page-top")
    topBtn.hide()
    //スクロールが100に達したらボタン表示
    jQuery(window).scroll(function () {
      if (jQuery(this).scrollTop() > 300) {
        topBtn.fadeIn()
      } else {
        topBtn.fadeOut()
      }
    })
    //スクロールしてトップ
    topBtn.click(function () {
      jQuery("body,html").animate(
        {
          scrollTop: 0,
        },
        500,
      )
      return false
    })
  })

  // [Header]
  jQuery(function () {
    var $header = jQuery("#top-head")
    // Nav Fixed
    jQuery(window).scroll(function () {
      if (jQuery(window).scrollTop() > 350) {
        $header.addClass("fixed")
      } else {
        $header.removeClass("fixed")
      }
    })

    // NOTE: prevLocation と同じだと発火しないので components/Header に残してある
    // Nav Toggle Button
    // jQuery("#nav-toggle").click(function () {
    //   $header.toggleClass("open")
    // })
  })

  jQuery(document).ready(function () {
    var headerHight = 150
    jQuery('a[href^="#"]').click(function () {
      if (
        location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        location.hostname === this.hostname
      ) {
        var jQuerytarget = jQuery(this.hash)
        jQuerytarget =
          (jQuerytarget.length && jQuerytarget) ||
          jQuery('[name=" + this.hash.slice(1) +"]')
        if (jQuerytarget.length) {
          var targetOffset = jQuerytarget.offset().top - headerHight
          jQuery("html,body").animate(
            { scrollTop: targetOffset },
            1000,
            "quart",
          )
          return false
        }
      }
    })
  })
  jQuery.easing.quart = function (x, t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b
  }

  jQuery(window).on("load", function () {
    // ページのURLを取得
    const url = jQuery(location).attr("href"),
      // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
      headerHeight = jQuery("header").outerHeight() + 30

    // urlに「#」が含まれていれば
    if (url.indexOf("#") !== -1) {
      // urlを#で分割して配列に格納
      const anchor = url.split("#"),
        // 分割した最後の文字列（#◯◯の部分）をtargetに代入
        target = jQuery("#" + anchor[anchor.length - 1]),
        // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
        position = Math.floor(target.offset().top) - headerHeight
      // positionの位置に移動
      jQuery("html, body").animate({ scrollTop: position }, 500)
    }
  })

  //検索窓
  document.addEventListener("touchstart", function () {}, true)

  if (location.pathname === "/") {
    // fadein
    // scroll page top
    // slider
    new Swiper(".slider_1", {
      // ここからオプション
      direction: "vertical",
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 5000,
      },
    })

    new Swiper(".slider_2", {
      autoplay: {
        delay: 5000,
      },
      speed: 1000,
      loop: true,
      loopAdditionalSlides: 1,
      slidesPerView: 3,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
      },
    })
  }

  if (
    /^\/access/.test(location.pathname) ||
    /^\/appointment_menu/.test(location.pathname) ||
    /^\/floor_guide/.test(location.pathname) ||
    /^\/hospitalization_guidance/.test(location.pathname) ||
    /^\/news/.test(location.pathname) ||
    /^\/our_hospital_menu/.test(location.pathname) ||
    /^\/privacy_policy/.test(location.pathname) ||
    /^\/recruit/.test(location.pathname) ||
    /^\/institutions/.test(location.pathname) ||
    /^\//.test(location.pathname)
  ) {
    // fadein
    // scroll page top
    // mo_footer
    jQuery(function () {
      var topBtn = jQuery("#mo_footer")
      topBtn.hide()
      //スクロールが100に達したらボタン表示
      jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 100) {
          topBtn.fadeIn()
        } else {
          topBtn.fadeOut()
        }
      })
    })
  }

  // viewport 固定
  const switchViewport = () => {
    const value =
      window.outerWidth < 320
        ? "width=320"
        : "width=device-width,initial-scale=1"
    const viewport = document.querySelector('meta[name="viewport"]')
    if (viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value)
    }
  }
  addEventListener("resize", switchViewport, false)
  switchViewport()

  // google fonts
  window.WebFontConfig = {
    google: { families: ["Lato", "Kiwi+Maru:wght@300;400"] },
    active: function () {
      sessionStorage.fonts = true
    },
  }
  ;(function () {
    var wf = document.createElement("script")
    wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
    wf.type = "text/javascript"
    wf.async = "true"
    var s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(wf, s)
  })()
}
