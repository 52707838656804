import { useState, useEffect } from "react"

/**
 * DOM マウント後に引数の内容を返す wrapper
 */
const ClientOnly = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }
  return <div {...delegated}>{children}</div>
}

export default ClientOnly
